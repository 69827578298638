import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { CustomerService } from "../service/CustomerService";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const SimBalance = () => {
    const [value8, setValue8] = useState({ id: "", name: "" });
    const [fromport, setFromPort] = useState({ port: "", name: "" });
    const [toport, setToport] = useState({ port: "", name: "" });
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [balancelist,setbalancelist] = useState([]);
    const [customer, setcustomer] = useState([]);
    const [dropcustomer, setdropcustomer] = useState([]);
    const [device, setdevice] = useState([]);
    const { isLoggedIn, user } = useSelector((state) => state.auth);

    const representativesItemTemplate = (option) => {
        // console.log(option);
        let info = option.name;
        return (
            <div className="p-multiselect-representative-option">
                
                {/* <img alt={option.name} src={`assets/demo/images/avatar/${option.image}`} width={32} style={{ verticalAlign: 'middle' }} /> */}
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">
                    
                    {info}
                </span>
            </div>
        );
    };
    const portItemTemplate = (option) => {
        let info = option.port;

        return (
            <div className="p-multiselect-representative-option">
                
                {/* <img alt={option.name} src={`assets/demo/images/avatar/${option.image}`} width={32} style={{ verticalAlign: 'middle' }} /> */}
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">
                    
                    {info}
                </span>
            </div>
        );
    };

    const onDeviceChange = (e) => {
        let customer_array = [];
        for (let index of customer) {
            if (index.name === e.value.name) {
                customer_array.push(index);
            }
        }

        setdropcustomer(customer_array);
        setValue8(e.value);
        setFromPort({ port: "", name: "" });
        setToport({ port: "", name: "" });
    };

    const onFromPortChange = (e) => {
        setFromPort(e.value);
    };

    const transferbalance = () => {
        
        // e.preventDefault();
        if (!value8.id) {
            toast.current.show({ severity: "error", summary: "Please select device.", detail: "Device can't be empty.", life: 3000 });
        }
        if (!fromport.port) {
            toast.current.show({ severity: "error", summary: "Please select from port.", detail: "From Port can't be empty.", life: 3000 });
        }
       
        if (fromport.port && value8.id) {
            setLoading(true);
            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            if(value8.name !== 'device1'){
                tableItem.device = value8.name+'_'+(fromport.port-200);
            }else{
                tableItem.device = value8.name+'_'+fromport.port;
            }
            tableItem.port = fromport.port;
            let items = [];
            customerService.getBalanceDetails(tableItem).then((data) => {
                setLoading(false);
                let list = {};
                if(data.code === 0 && data.status === 'success')
                {
                    list["port"] = data.port;
                    list["name"] = value8.name+'_'+fromport.port;
                    list["balance"] = parseFloat(data.message.split('AED ')[1]);
                    list["message"] = data.message;
                    items.push(list);
                }
                else{
                    list["port"] = data.port;
                    list["name"] = value8.name+'_'+fromport.port;
                    list["balance"] = 0;
                    list["message"] = data.message;
                    items.push(list);
                }
                setbalancelist(items);
             });
            
        }
    };
    const getallbalance = () => {
        setLoading(true);
            let items = [];
            setLoading(true);
            for (let index of customer) {
            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            if(index.name !== 'device1'){
                tableItem.device = index.name+'_'+(index.port-200);
            }else{
                tableItem.device = index.name+'_'+index.port;
            }
            // tableItem.device = index.name+'_'+index.port;
            tableItem.port = index.port;
                customerService.getBalanceDetails(tableItem).then((data) => {
               
                    let list = {};
                    if(data.code === 0 && data.status === 'success')
                    {
                        list["port"] = data.port;
                        list["name"] = data.device.split('_')[0];
                        list["balance"] = parseFloat(data.message.split('AED ')[1]);
                        list["message"] = data.message;
                        items.push(list);
                    }
                    else{
                        list["port"] = data.port;
                        list["name"] = data.device.split('_')[0];
                        list["balance"] = 0;
                        list["message"] = data.message;
                        items.push(list);
                    }
                    if(customer.length === items.length){
                        setbalancelist(items);
                        setLoading(false);
                    }
                 });
            }
    };
    const customerService = new CustomerService();

    useEffect(() => {
        let tableItem = {};
        tableItem.token = user.token;
        tableItem.role = user.user.role;
        tableItem.id = user.user.id;

        // console.log(tableItem);

        customerService.getSimList(tableItem).then((data) => {
            if (isIterable(data)) {
            let items = [];
            let names = [];
            let i = 1;
            for (let index of data) {
                let list = {};
                list["port"] = index.port;
                list["name"] = index.name.split("_")[0];
                items.push(list);

                let nameArray = names.map(function (el) {
                    return el.name;
                });
                if (!nameArray.includes(index.name.split("_")[0])) {
                    let kist1 = {};
                    kist1["name"] = index.name.split("_")[0];
                    kist1["id"] = i;
                    names.push(kist1);
                    i++;
                }
            }
            setdevice(names);
            setcustomer(items);
        }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const isIterable= (obj) => {
        // checks for null and undefined
        if (obj == null) {
          return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
      }

      const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span className="image-text">{data.name}</span>
            </React.Fragment>
        );
    }

    // const footerTemplate = (data) => {
    //     return (
    //         <React.Fragment>
    //             <td colSpan="4" style={{ textAlign: 'right' }} className="text-bold pr-6">Total Customers</td>
    //             <td>{calculateCustomerTotal(data.representative.name)}</td>
    //         </React.Fragment>
    //     );
    // }
    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "AED" });
    };
    const calculateTotal = (historynew) => {
        let total = 0;
        if (historynew) {
            for (let data of historynew) {
                data = data.balance;
                total += data;
            }
        }
        return "Total Available Balance :-" + formatCurrency(total);
    };
    if (!isLoggedIn) {
        // window.location.reload(false);
        return <Redirect to="/" />;
    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5> Sim Balance </h5>
                    <div className="grid table-demo">
                        <Toast ref={toast} />
                        <div className="col-12">
                            <div className="card p-fluid">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="dropdown"> Device Name </label> <Dropdown id="dropdown" value={value8} options={device} itemTemplate={representativesItemTemplate} onChange={(e) => onDeviceChange(e)} optionLabel="name" placeholder="select device" className="p-column-filter" />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="fromdropdown"> Sim Port </label>
                                        <Dropdown id="fromdropdown" value={fromport} options={dropcustomer} itemTemplate={portItemTemplate} onChange={(e) => onFromPortChange(e)} optionLabel="port" placeholder="select device" className="p-column-filter" />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-8"> </div>
                                    <div className="field col">
                                        <Button label="Submit" disabled={loading} onClick={transferbalance}>
                                            
                                        </Button>
                                    </div>
                                    <div className="field col">
                                        <Button label="All Balance" disabled={loading} onClick={getallbalance}>
                                            
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    {/* <h5>Balance List</h5> */}
                    <div className="formgrid grid">
                        <div className="col-12 col">
                            <div className="flex align-items-center flex-column sm:flex-row">
                                <Button type="button" label={calculateTotal(balancelist)} className="mr-2"></Button>
                               
                            </div>
                        </div>
                    </div>
                    <DataTable value={balancelist}
                     rowGroupMode="subheader" groupRowsBy="name"
                        sortMode="single" 
                        sortField="port" 
                        sortOrder={1} scrollable scrollHeight="400px"
                        rowGroupHeaderTemplate={headerTemplate}
                        //  rowGroupFooterTemplate={footerTemplate}
                         responsiveLayout="scroll">
                        <Column field="name" header="Name" ></Column>
                        <Column field="port" header="Port" ></Column>
                        <Column field="balance" header="Balance" ></Column>
                        <Column field="message" header="Message"></Column>
                        {/* <Column field="status" header="Status" body={statusBodyTemplate} style={{ minWidth: '200px' }}></Column> */}
                       
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
