import React ,{useState,useRef} from "react";
import { Link } from "react-router-dom";
import { logout } from "./redux/actions";
import { useDispatch,useSelector } from "react-redux";
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
export const AppTopbar = (props) => {
    const dispatch = useDispatch();
    const handelLogout = () => {
        dispatch(logout());
    };
    const toast = useRef(null);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const accept = () => {
        setDisplayConfirmation(false)
        handelLogout();
    };

    const reject = () => {
        setDisplayConfirmation(false)
        // toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 5000 });
    };
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => reject()} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => accept()} className="p-button-text" autoFocus />
        </>
    );
    const {  isLoggedIn,user } = useSelector((state) => state.auth);
    console.log(user);
    const styleObj = {
        opacity: 0
    }
    return (
        <div className="layout-topbar" style={ !isLoggedIn ? styleObj : {}}>
            <Toast ref={toast} /> 
                        <Dialog header="Confirmation" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                <span>Are you sure you want to proceed?</span>
                            </div>
                        </Dialog>
           
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo.png' : 'assets/layout/images/logo.png'} alt="logo"/>
                {/* <span>API ENGINE</span> */}
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={() => setDisplayConfirmation(true)} >
                <i className="pi pi-fw pi-sign-out" />
            </button>
            {user &&  
                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={() => setDisplayConfirmation(true)} >
                            <i className="pi pi-fw pi-sign-out"/>
                            <span>Logout</span>
                        </button>
                    </li>
                   {/* <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                    </li> */}
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            
                        { user.user.icon !='' && 
                                  
                                  <i> <img 
                                     src={'https://openonline.in/staging_paykart/img/Users/Icon/' +user.user.icon}/>
                                    </i>
                        }
                        { user.user.icon =='' && 
                                <i className="pi pi-user"/>
                            }
                            
                           
                        </button> <br></br><span>{user.user.name}</span>
                    </li>
                </ul> 
                }
        </div>
    );
};
