import axios from "axios";
let  apiurl = 'http://89.187.84.162:8002';
export class CustomerService {
    

    getCustomersMedium() {
        return axios.get("assets/demo/data/customers-medium.json").then((res) => res.data.data);
    }

    getCustomersLarge() {
        return axios.get("assets/demo/data/customers-large.json").then((res) => res.data.data);
    }
    getHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: apiurl +`/api/v1/History`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: apiurl +`/api/v1/History`,
                method: httpmethod,
                data: tableItem,
            };
        }
        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }
    getRechargeHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: apiurl +`/api/v1/RechargeHistory`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: apiurl +`/api/v1/RechargeHistory`,
                method: httpmethod,
                data: tableItem,
            };
        }
        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }

    getSalesReport(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: apiurl +`/api/v1/SalesReport`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: apiurl +`/api/v1/SalesReport`,
                method: httpmethod,
                data: tableItem,
            };
        }
        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }

    getVoucherSoldReport(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: apiurl +`/api/v1/VoucherSoldReport`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: apiurl +`/api/v1/VoucherSoldReport`,
                method: httpmethod,
                data: tableItem,
            };
        }
        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }
    getOperators(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: apiurl +`/api/v1/getOperators`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: apiurl +`/api/v1/getOperators`,
                method: httpmethod,
                data: tableItem,
            };
        }
        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }
    getRechargeOperators(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: apiurl +`/api/v1/getRechargeOperators`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: apiurl +`/api/v1/getRechargeOperators`,
                method: httpmethod,
                data: tableItem,
            };
        }
        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }
    getTransferHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/TransferHistory`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    saveamzvoucher(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: apiurl +`/api/v1/add_all_amzvouchers`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getAmzVoucherHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/AmzVoucherHistory`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }
    savevoucher(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: apiurl +`/api/v1/add_all_vouchers`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getVoucherHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/VoucherHistory`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }
    getSimList(tableItem) {
        let httpmethod = 'get';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/deviceinfo`,
            method: httpmethod
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }


    getDashboardsucessfailure(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/Dashboardsucessfailure`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    getCustomers(tableItem) {
        let httpmethod = 'get';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/ListCustomers`,
            method: httpmethod
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }

    getBalanceDetails(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/balancedetails`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data;

            })
            .catch((error) => {
                return error;

            });
    }

    getUsers(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token;
        let options = {};

        options = {
            url: apiurl +`/api/v1/getUsers`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    simplerecharge(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token;
        let options = {};

        options = {
            url: apiurl +`/api/v1/simplerecharge`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
    geteditdeviceinfo(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: apiurl +`/api/v1/editdeviceinfo`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }

    addCustPayment(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: apiurl +`/api/v1/addCustPayment`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info_User;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }

    getedituserinfo(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: apiurl +`/api/v1/getedituserinfo`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getpasschange(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: apiurl +`/api/v1/getpasschange`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    geteditorderinfo(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: apiurl +`/api/v1/editorderinfo`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }

    getDashboardSummary(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/DashboardSummary`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0][0];
            })
            .catch((error) => {
                return error;
            });
    }

    getDashboardSummaryMonthly(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/DashboardSummaryMonthly`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    getDashboardSummaryHourly(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/DashboardSummaryHourly`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    getRecentTrans(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/RecentTrans`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    getPaymentHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: apiurl +`/api/v1/getPaymentHistory`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

}