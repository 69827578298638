import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import { AppLogin } from "./AppLogin";
import { BalanceTransfer } from "./components/BalTranfer";
import { Dashboard } from "./components/Dashboard";
import { ButtonDemo } from "./components/ButtonDemo";
import { ChartDemo } from "./components/ChartDemo";
import { Documentation } from "./components/Documentation";
import { FileDemo } from "./components/FileDemo";
import { FloatLabelDemo } from "./components/FloatLabelDemo";
import { FormLayoutDemo } from "./components/FormLayoutDemo";
import { InputDemo } from "./components/InputDemo";
import { ListDemo } from "./components/ListDemo";
import { MenuDemo } from "./components/MenuDemo";
import { MessagesDemo } from "./components/MessagesDemo";
import { MiscDemo } from "./components/MiscDemo";
import { OverlayDemo } from "./components/OverlayDemo";
import { PanelDemo } from "./components/PanelDemo";
import { TableDemo } from "./components/TableDemo";
import { History } from "./components/History";
import { Recharges } from "./components/Recharges";
import {SalesReport} from "./components/SalesReport";
import { VouchersReport } from "./components/VouchersReport";
import { TreeDemo } from "./components/TreeDemo";
import { InvalidStateDemo } from "./components/InvalidStateDemo";
import { BlocksDemo } from "./components/BlocksDemo";
import { IconsDemo } from "./components/IconsDemo";
import { Simlist } from "./components/simlist";
import { Recharge } from "./components/Recharge";
import {AmzVoucher} from "./components/AmzVoucher";
import {Vouchers} from "./components/Vouchers";
import { SimBalance } from "./components/simbalance";
import { Crud } from "./pages/Crud";
import { EmptyPage } from "./pages/EmptyPage";
import { TimelineDemo } from "./pages/TimelineDemo";
import {Users} from "./components/users"
import {Payments} from "./components/payments"
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { useSelector } from "react-redux";

const App = () => {
    const { isLoggedIn } = useSelector((state) => state.auth);

    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("dark");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    // const rightmenu = false;
    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
        // if (isLoggedIn) {
        //     alert("Please");
        //     return <Redirect to="/" />;
        // } else {
        //     alert("not");
        //     // return <Redirect to="/login" />;
        //     return <Route path="/login" exact component={Dashboard} />
        // }
    }, [mobileMenuActive]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "Home",
            items: [
                // {
                //     label: "Dashboard",
                //     icon: "pi pi-fw pi-home",
                //     to: "/",
                // },
                {
                    label: "Transactions Report",
                    icon: "pi pi-fw pi-calendar",
                    to: "/",
                },
                {
                    label: "Recharges Report",
                    icon: "pi pi-fw pi-tablet",
                    to: "/recharges",
                },
               
                {
                    label: "Vouchers Report",
                    icon: "pi pi-fw pi-tag",
                    to: "/vouchersreport",
                } ,
                {
                    label: "Sales Report",
                    icon: "pi pi-fw pi-calendar",
                    to: "/salesreport",
                } ,
                {
                    label: "Device Info",
                    icon: "pi pi-fw pi-id-card",
                    to: "/simlist",
                },
                {
                    label: "Balance Transfer",
                    icon: "pi pi-fw pi-money-bill",
                    to: "/baltransfer",
                },
                {
                    label: "Recharge",
                    icon: "pi pi-fw pi-tablet",
                    to: "/recharge",
                },
                {
                    label: "Sim Balance",
                    icon: "pi pi-fw pi-ticket",
                    to: "/simbalance",
                },
                {
                    label: "Users",
                    icon: "pi pi-fw pi-users",
                    to: "/users",
                },
                {
                    label: "Payments",
                    icon: "pi pi-fw pi-dollar",
                    to: "/payments",
                },{
                    label: "Vouchers",
                    icon: "pi pi-fw pi-tags",
                    to: "/vouchers",
                },{
                    label: "Amz Vouchers",
                    icon: "pi pi-fw pi-tags",
                    to: "/amzvoucher",
                },
                
            ],
        },
        // {
        //     label: 'UI Kit', icon: 'pi pi-fw pi-sitemap',
        //     items: [
        //         { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
        //         { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
        //         { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
        //         { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
        //         { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
        //         { label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
        //         { label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
        //         { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
        //         { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
        //         { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
        //         { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu' },
        //         { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
        //         { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' },
        //         { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' },
        //         { label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' },
        //     ]
        // },
        // {
        //     label: "UI Blocks",
        //     items: [
        //         { label: "Free Blocks", icon: "pi pi-fw pi-eye", to: "/blocks", badge: "NEW" },
        //         { label: "All Blocks", icon: "pi pi-fw pi-globe", url: "https://www.primefaces.org/primeblocks-react" },
        //     ],
        // },
        // {
        //     label: "Icons",
        //     items: [{ label: "PrimeIcons", icon: "pi pi-fw pi-prime", to: "/icons" }],
        // },
        // {
        //     label: "Pages",
        //     icon: "pi pi-fw pi-clone",
        //     items: [
        //         { label: "Crud", icon: "pi pi-fw pi-user-edit", to: "/crud" },
        //         { label: "Timeline", icon: "pi pi-fw pi-calendar", to: "/timeline" },
        //         { label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty" },
        //     ],
        // },
        // {
        //     label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
        //     items: [
        //         {
        //             label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
        //             items: [
        //                 {
        //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
        //                     items: [
        //                         { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
        //                     ]
        //                 },
        //                 {
        //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
        //                     items: [
        //                         { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark' }
        //                     ]
        //                 },
        //             ]
        //         },
        //         {
        //             label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
        //             items: [
        //                 {
        //                     label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
        //                     items: [
        //                         { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark' },
        //                     ]
        //                 },
        //                 {
        //                     label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
        //                     items: [
        //                         { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark' }
        //                     ]
        //                 }
        //             ]
        //         }
        //     ]
        // },
        // {
        //     label: 'Get Started',
        //     items: [
        //         { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
        //         { label: 'View Source', icon: 'pi pi-fw pi-search', command: () => { window.location = "https://github.com/primefaces/sakai-react" } }
        //     ]
        // }
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });
    // const loginClass = () => {
    //     if (!mobileMenuActive) return "ml-8 pl-6";
    //     else return "m-0 p-0";
    // };

    const styleObj = {
        opacity: 0,
    };
    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" style={!isLoggedIn ? styleObj : {}} onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    {/* <Route path="/" exact component={Dashboard} /> */}
                    <Route path="/formlayout" component={FormLayoutDemo} />
                    <Route path="/input" component={InputDemo} />
                    <Route path="/floatlabel" component={FloatLabelDemo} />
                    <Route path="/invalidstate" component={InvalidStateDemo} />
                    <Route path="/button" component={ButtonDemo} />
                    <Route path="/table" component={TableDemo} />
                    <Route path="/list" component={ListDemo} />
                    <Route path="/tree" component={TreeDemo} />
                    <Route path="/panel" component={PanelDemo} />
                    <Route path="/overlay" component={OverlayDemo} />
                    <Route path="/menu" component={MenuDemo} />
                    <Route path="/messages" component={MessagesDemo} />
                    <Route path="/blocks" component={BlocksDemo} />
                    <Route path="/icons" component={IconsDemo} />
                    <Route path="/file" component={FileDemo} />
                    <Route path="/chart" component={ChartDemo} />
                    <Route path="/misc" component={MiscDemo} />
                    <Route path="/timeline" component={TimelineDemo} />
                    <Route path="/crud" component={Crud} />
                    <Route path="/empty" component={EmptyPage} />
                    <Route path="/baltransfer" component={BalanceTransfer} />
                    <Route path="/recharge" component={Recharge} />
                    <Route path="/documentation" component={Documentation} />
                    <Route path="/" exact component={History} />
                    <Route path="/history"  component={History} />
                    <Route path="/recharges" component={Recharges} />
                    <Route path="/vouchersreport" component={VouchersReport} />
                    <Route path="/salesreport" component={SalesReport} />
                    <Route path="/simlist" component={Simlist} />
                    <Route path="/users" component={Users} />
                    <Route path="/simbalance" component={SimBalance} />
                    <Route path="/payments" component={Payments} />
                    <Route path="/amzvoucher" component={AmzVoucher} />
                    <Route path="/vouchers" component={Vouchers} />
                    <div className={ !isDesktop() ? "" : "ml-8 pl-8"}>
                        <Route path="/login" component={AppLogin} />
                    </div>
                </div>
                {/* <AppLogin onLayoutModeChange={onLayoutModeChange} /> */}
                {/* {!isLoggedIn && (
                    <div className="ml-8 pl-6">
                        <div className="flex align-items-center m-8">
                            <Route path="/login" component={AppLogin} />
                        </div>
                    </div>
                )}  */}

                <AppFooter layoutColorMode={layoutColorMode} />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};
export default App;
