import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { CustomerService } from "../service/CustomerService";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from 'primereact/inputswitch';
export const Recharges = () => {
    const [displayBasic, setDisplayBasic] = useState(false);
    const basicDialogFooter = <Button type="button" label="Dismiss" onClick={() => setDisplayBasic(false)} icon="pi pi-check" className="p-button-secondary" />;
    const statustype = [
        { id: "Cr", name: "Credit" },
        { id: "Dr", name: "Debit" },
    ];
   
    const toast = useRef(null);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [historynew, setHistorynew] = useState(null);
    const [number, setNumber] = useState("");
    const [number1, setNumber1] = useState("");
    const [number2, setNumber2] = useState("");
    const [singelrowData, setSingelrowData] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [filters1, setFilters1] = useState(null);
    const [value8, setValue8] = useState({ id: null, name: null });
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");
    const [loading1, setLoading1] = useState(true);
    const [response, setResponse] = useState(true);
    const [startdate, setStartdate] = useState(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
    const [enddate, setEnddate] = useState(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
    const onChangeStartdate = (e) => {
        const startdate = e.target.value;
        setStartdate(startdate);
    };
    const [dropdownItem, setDropdownItem] = useState(null);
    const [dropdownItems, setDropdownItems] = useState( [
        { name: 'Select None', code: '' }
    ]);
    const [switchValue, setSwitchValue] = useState(false);
    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <div className="actions">
                    <Button icon="pi pi-ellipsis-v" className="p-button-rounded p-button-warning" onClick={() => showresponse(rowData)} />
                </div>
            </div>
        );
    };
    const showresponse = (rowData) => {
        setResponse(rowData.narration);
        setDisplayBasic(true);
    };

    const exportCSV = () => {
        // Headers for each column
        let headers = ["requested_id_no,maintype,profit,status,user_commission,title,date,amount,recharge_tpr_num,consider_as,user_commission_type,message"];

        // Convert users data to a csv

        let usersCsv = historynew.reduce((acc, detail) => {
            if (detail.consider_as === "Dr") {
                detail.consider_as = "Debit";
                // detail.date = new Date(detail.date);
                // var str = detail.date.toLocaleString("en-GB", { timeZone: "Asia/Dubai" });
                // detail.date = str.replace(/,/, " ");
            } else if (detail.consider_as === "Cr") {
                detail.consider_as = "Credit";
                // detail.date = new Date(detail.date);
                // var str2 = detail.date.toLocaleString("en-GB", { timeZone: "Asia/Dubai" });
                // detail.date = str2.replace(/,/, " ");
            } 
            const { requested_id_no, maintype,total_deliverable_amount,status,user_commission, title, date, amount, recharge_tpr_num, consider_as,user_commission_in, user_commission_type,narration } = detail;
            acc.push([requested_id_no, maintype,total_deliverable_amount,status,user_commission, title, date, amount, recharge_tpr_num, consider_as,user_commission_in, user_commission_type,narration].join(","));
            return acc;
        }, []);

        downloadFile({
            data: [...headers, ...usersCsv].join("\n"),
            fileName: "recharge_history.csv",
            fileType: "text/csv",
        });
    };
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });

        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    const onChangeEnddate = (e) => {
        const enddate = e.target.value;
        setEnddate(enddate);
    };

    const statuses = ["Dr", "Cr"];
    // let startdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    // let enddate = (new Date(Date.now() - new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    const customerService = new CustomerService();

    useEffect(() => {
        let tableItem1 = {};
        tableItem1.token = user.token;
    customerService.getRechargeOperators(tableItem1).then((data) => {
        if (isIterable(data)) {
            let data1=[{ name: 'Select None', code: '' }]
            data.forEach(element => {
                data1.push({ name: element.title, code: element.id })        
            });
            
            // console.log(data);
            setDropdownItems(data1);
        }
        setLoading1(false);
    });

        var today = (new Date(Date.now() - new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        var tomorrow = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
        tomorrow.setDate(tomorrow.getDate()+1);
        setStartdate(today);
        setEnddate(tomorrow.toISOString().substr(0, 10));
        if (user) {
            setLoading1(true);

            let tableItem = {};
            tableItem.token = user.token;
            tableItem.country_code= user.country_code;
            // tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.date = today;
            tableItem.enddate = tomorrow;
            tableItem.number = number;
            tableItem.amount = number2;
            tableItem.operator = dropdownItem;
            // console.log(tableItem);
            customerService.getRechargeHistory(tableItem).then((data) => {
                if (isIterable(data)) {
                    setHistorynew(getCustomersnew(data));
                }
                setLoading1(false);
            });

            initFilters1();
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    const isIterable = (obj) => {
        // checks for null and undefined
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === "function";
    };
    const onNumberChange = (e) => {
        setNumber(e.target.value);
    };
    const onNumber1Change = (e) => {
        setNumber1(e.target.value);
    };
    const onNumber2Change = (e) => {
        setNumber2(e.target.value);
    };
    const hideDialog = () => {
        // setSubmitted(false);
        setProductDialog(false);
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < historynew.length; i++) {
            if (historynew[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };
    const saveProduct = () => {
        if (!value8.id) {
            toast.current.show({ severity: "error", summary: "Please select consider_as.", detail: "consider_as can't be empty.", life: 3000 });
        }
        const customerService = new CustomerService();

        if (value8.id && singelrowData.id) {
            let _products = [...historynew];
            let _product = { ...singelrowData };
            let tableItem = {};
            tableItem.id = _product.id;
            tableItem.consider_as = value8.id;
            tableItem.token = user.token;
            tableItem.country_code= user.country_code;
            customerService
                .geteditorderinfo(tableItem)
                .then((data) => {
                    setProductDialog(false);
                    const index = findIndexById(_product.id);
                    // alert(_product.status);
                    _product.status = value8.id;
                    // alert(_product.status);
                    _products[index] = _product;

                    toast.current.show({ severity: "success", summary: "Successful", detail: "Order Updated", life: 3000 });
                })
                .catch((error) => {
                    setProductDialog(false);
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
                });
            setHistorynew(_products);
            setProductDialog(false);
            setLoading1(true);
            searchnewlist();
        }
    };
    const searchnewlist = () => {
        if (user) {
            let date1 = new Date(startdate);
            let date2 = new Date(enddate);
            if (date2 < date1) {
                toast.current.show({ severity: "error", summary: "End Date Should Always Greater Or Equal To Start Date!", detail: "", life: 5000 });
                return false;
            }

            setLoading1(true);
            date2.setDate(date2.getDate()+1);
            let tableItem = {};
            tableItem.token = user.token;
            tableItem.country_code= user.country_code;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.date = date1.toLocaleDateString().split('/')[2]+'-'+date1.toLocaleDateString().split('/')[1]+'-'+date1.toLocaleDateString().split('/')[0]
            tableItem.enddate = date2.toLocaleDateString().split('/')[2]+'-'+date2.toLocaleDateString().split('/')[1]+'-'+date2.toLocaleDateString().split('/')[0]
            
            tableItem.number = number;
            tableItem.amount = number2;
            tableItem.operator = dropdownItem;
            customerService.getRechargeHistory(tableItem).then((data) => {
                setHistorynew(getCustomersnew(data));
                setLoading1(false);
            });

            initFilters1();
        }
    };

    const getCustomersnew = (data) => {
        if (data) {
            return [...(data || [])].map((d) => {
                d.date = d.created.split('.')[0].split('T')[0] +' '+ d.created.split('.')[0].split('T')[1];
                 if (d.consider_as === "Cr") {
                    d.consider_as = "Cr";
                } else if (d.consider_as === "Dr") {
                    d.consider_as = "Dr";
                }
                return d;
            });
        }
    };

    const formatDate = (value) => {
        var dateA = new Date(value);
        var str = dateA.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
        return str.replace(/,/, " ");
        // return dateA;
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "AED" });
    };

    const clearFilter1 = () => {
        initFilters1();
    };

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1["global"].value = value;

        setFilters1(_filters1);
        // console.log(value);
        setGlobalFilterValue1(value);
    };

    const initFilters1 = () => {
        setFilters1({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            // requested_id_no: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            title: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            maintype: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            // user_commission: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            recharge_tpr_num: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            consider_as: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            // user_commission_type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue1("");
    };

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span style={{ textAlign: "center", color: "purple" }} className="text-bold pr-6">
                    <b>Total Recharges :-{calculateTotalrecharges(historynew)}</b>
                </span>
                <span style={{ textAlign: "center", color: "green" }} className="text-bold pr-6">
                    <b>Total Success :-{calculateTotalsuccess(historynew)}</b>
                </span>
                <span style={{ textAlign: "center", color: "red" }} className="text-bold pr-6">
                    <b>Total Failed :-{calculateTotalfailed(historynew)}</b>
                </span>
                <span style={{ textAlign: "center", color: "blue" }} className="text-bold pr-6">
                    <b>Total Success Amount :-{calculateTotal(historynew)}</b>
                </span>
            </React.Fragment>
        );
    };
    const calculateTotal = (historynew) => {
        let total = 0;
        if (historynew) {
            for (let data of historynew) {
                if (data.status == "Success") {
                    total += data.total_deliverable_amount;
                }
            }
        }
        return "Total Profit :-" + total.toLocaleString("en-US", { style: "currency", currency: "AED" });
    };
    const calculateTotalfailed = (historynew) => {
        let total = 0;
        if (historynew) {
            for (let data of historynew) {
                if (data.status == "Fail") {
                    total++;
                }
            }
        }
        return "Total Failed :-" + total;
    };
    const calculateTotalsuccess = (historynew) => {
        let total = 0;
        if (historynew) {
            for (let data of historynew) {
                if (data.status == "Success") {
                    total++;
                }
            }
        }
        return "Total Success :-" + total;
    };
    const calculateTotalrecharges = (historynew) => {
        let total = 0;
        if (historynew) {
            for (let data of historynew) {
                data = data;
                total++;
            }
        }
        return "Total Recharges :-" + total;
    };
    const renderHeader1 = () => {
        return (
            <div className="formgrid grid">
                <div className="col-12">
                <div className="card">
                    {/* <h5>Advanced</h5> */}
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-3">
                            {/* <label htmlFor="firstname2">Firstname</label> */}
                            <Calendar value={startdate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeStartdate} placeholder="Sold From(yyyy-mm-dd)" mask="9999-99-99" />
                        
                        </div>
                        <div className="field col-12 md:col-3">
                            {/* <label htmlFor="lastname2">Lastname</label> */}
                            <Calendar value={enddate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeEnddate} placeholder="Sold To(yyyy-mm-dd)" mask="9999-99-99" />
                       
                        </div>
                        <div className="field col-12 md:col-3">
                            <InputText id="number" value={number} onChange={(e) => onNumberChange(e)} type="text" className="p-button-outlined mb-2" required placeholder="Number" />
                        
                        </div>
                        {/* <div className="field col-12 md:col-3">
                           
                             <InputText id="number" value={number1} onChange={(e) => onNumber1Change(e)} type="text" className="p-button-outlined mb-2" required placeholder="Serial No." />
                            

                        </div> */}
                        <div className="field col-12 md:col-3">
                            {/* <label htmlFor="state">State</label> */}
                            {/* <InputText id="number" value={number1} onChange={(e) => onNumber1Change(e)} type="text" className="p-button-outlined mb-2" required placeholder="Operator" /> */}
                        
                            <Dropdown id="state" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} options={dropdownItems} optionLabel="name" placeholder="Select Operator"></Dropdown>
                        </div>
                        <div className="field col-12 md:col-3">
                            {/* <label htmlFor="state">State</label> */}
                            <InputText id="number" value={number2} onChange={(e) => onNumber2Change(e)} type="text" className="p-button-outlined mb-2" required placeholder="Denomination" />
                            
                        </div>
                        {/* <div className="field col-12 md:col-3">
                            <span className="mt-2 mr-2">Pin Include</span>
                            <InputSwitch checked={switchValue} onChange={(e) => setSwitchValue(e.value)} />
                        </div> */}
                        <div className="field col-12 md:col-3">
                            {/* <label htmlFor="state">State</label> */}
                            <Button type="button" icon="pi pi-search" label="Search" className="p-button-outlined mb-2" onClick={searchnewlist} />
                        </div>
                        
                    </div>
                </div>
                </div>
                </div>
            // <div>
            //     <div className="flex justify-content-between  card border-1 surface-border  flex-column sm:flex-row mb-2">
            //         <Calendar value={startdate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeStartdate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
            //         <Calendar value={enddate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeEnddate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
            //         <InputText id="number" value={number} onChange={(e) => onNumberChange(e)} type="text" className="p-button-outlined mb-2" required placeholder="Number" />
                                        
            //         <Button type="button" icon="pi pi-search" label="Search" className="p-button-outlined mb-2" onClick={searchnewlist} />
            //         {/* <Button label="Export" icon="pi pi-upload" className="p-button-help " onClick={exportCSV} /> */}
            //     </div>
            //     <div className="flex justify-content-between flex-column sm:flex-row ">
            //         <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined mb-2" onClick={clearFilter1} />
            //         <Button type="button" icon="pi pi-refresh" label="Refresh" className="p-button-outlined mb-2" onClick={searchnewlist} />

            //         <span className="p-input-icon-left mb-2">
            //             <i className="pi pi-search" />
            //             <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" style={{ width: "100%" }} />
            //         </span>
            //     </div>
            // </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return rowData.date;
        // return formatDate(rowData.date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.amount);
    };
    const maintypeBodyTemplate =(rowData)=> {
        // if(user){
        //     if(user.user.role == 'admin')
        //     {
                 return rowData.maintype;
            // }
            // else{
                // return 'maintype';
            // }
        // }
        // else{
            // return 'maintype';
        // }
    }
    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="AED" locale="en-US" />;
    };

    const statusBodyTemplate = (rowData) => {
        // console.log(rowData)
        if (rowData.consider_as === "Dr") {
            return (
                <span onClick={() => editProduct(rowData)} className={`customer-badge status-unqualified`}>
                    Debit
                </span>
            );
        } else if (rowData.consider_as === "Cr") {
            return (
                <span onClick={() => editProduct(rowData)} className={`customer-badge status-qualified`}>
                    Credit
                </span>
            );
        }
    };

    const BodyTemplate = (rowData) => {
        // console.log(rowData)
        if (rowData.status === "Fail") {
            return (
                <span onClick={() => editProduct(rowData)} className={`customer-badge status-unqualified`}>
                    Fail
                </span>
            );
        } else if (rowData.status === "Success") {
            return (
                <span onClick={() => editProduct(rowData)} className={`customer-badge status-qualified`}>
                    Success
                </span>
            );
        }
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a consider_as" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        if (option === "Dr") {
            return <span className={`customer-badge status-unqualified`}>Debit</span>;
        } else if (option === "Cr") {
            return <span className={`customer-badge status-qualified`}>Credit</span>;
        } 
    };
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const editProduct = (rowData) => {
        // console.log(user.user.role)
        if (user.user.role === "admin") {
            let list = {};

            if (rowData.consider_as === "Dr") {
                list["id"] = "Dr";
                list["name"] = "Debit";
            } else if (rowData.consider_as === "Cr") {
                list["id"] = "Cr";
                list["name"] = "Credit";
            } 
            setValue8(list);
            setSingelrowData(rowData);
            setProductDialog(true);
        }
    };
    const header1 = renderHeader1();
    if (!isLoggedIn) {
        return <Redirect to="/" />;
    }
    return (
        
        <div className="grid table-demo">
            <Toast ref={toast} />
            <Dialog header="Message" visible={displayBasic} style={{ width: "450px" }} className="p-fluid" modal footer={basicDialogFooter} onHide={() => setDisplayBasic(false)}>
                <p>{response}</p>
            </Dialog>
            <Dialog visible={productDialog} style={{ width: "450px" }} header="Status Info" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                <div className="formgrid grid">
                    <div className="col-12 col">
                        {/* <label htmlFor="dropdown">Status</label> */}
                        <Dropdown id="dropdown" options={statustype} value={value8} onChange={(e) => setValue8(e.value)} optionLabel="name" placeholder="Select consider_as" className="p-column-filter"></Dropdown>
                    </div>
                </div>
            </Dialog>
            <div className="col-12">
                <div className="card">
                    <h5>Recharges History</h5>
                    <div className="formgrid grid">
                        <div className="col-10 col">
                            <div className="flex align-items-center flex-column sm:flex-row">
                                <Button type="button" label={calculateTotalrecharges(historynew)} className="mr-2 p-button-warning"></Button>
                                <Button type="button" label={calculateTotalsuccess(historynew)} className="mr-2"></Button>
                                <Button type="button" label={calculateTotalfailed(historynew)} className="mr-2 p-button-danger"></Button>
                                <Button type="button" label={calculateTotal(historynew)} className="mr-2 p-button-success"></Button>
                            </div>
                        </div>
                        <div className="col-2 col mb-2">
                        <div className="flex align-items-center flex-column sm:flex-row">
                                
                                <Button label="Export" icon="pi pi-upload" className="p-button-help mr-2 " onClick={exportCSV} />
                   
                            </div>
                        </div>
                    </div>
                    <DataTable
                        // ref={dt}
                        value={historynew}
                        paginator
                        className="p-datatable-gridlines datatable-responsive"
                        showGridlines
                        rows={15}
                        // rowGroupMode="subheader"
                        // rowGroupHeaderTemplate={headerTemplate}
                        rowsPerPageOptions={[10, 15, 50]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        sortMode="single"
                        sortField={["requested_id_no", "maintype","user_commission", "title","status", "recharge_tpr_num","date", "amount","user_commission_in", "user_commission_type"]}
                        sortOrder={1}
                        dataKey="id"
                        filters={filters1}
                        filterDisplay="menu"
                        loading={loading1}
                        globalFilterFields={["requested_id_no", "maintype","user_commission", "title","status", "date", "amount", "recharge_tpr_num", "consider_as","user_commission_in", "user_commission_type"]}
                        header={header1}
                        emptyMessage="No History found."
                    >
                        <Column  field="requested_id_no" header="Trans. Id"  />
                        <Column sortable field="title" header="Operator" filter filterPlaceholder="Enter Operator" />
                        {/* <Column sortable   filterField="maintype" header="maintype" body={maintypeBodyTemplate}  /> */}
                        <Column sortable field="maintype" header="maintype" filter filterPlaceholder="Enter maintype" style={{ minWidth: '12rem' }} />
                        <Column field="status" header="Status" body={BodyTemplate} />
                        <Column field="total_deliverable_amount" header="Profit"  />
                        {/* <Column field="user_commission" header="commi."  /> */}
                        {/* <Column field="user_commission_type" header="commi. type"  /> */}
                        <Column field="recharge_tpr_num" filter filterPlaceholder="Enter Number"  header="No." />
                        <Column sortable field="date" header="Date" filterField="date" dataType="date" body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
                        <Column header="Amt." filterField="amount" dataType="numeric" body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
                        {/* <Column sortable field="consider_as" header="Type" filterMenuStyle={{ width: "14rem" }} body={statusBodyTemplate} filter filterElement={statusFilterTemplate} /> */}
                        <Column sortable field="consider_as" header="Type" body={statusBodyTemplate} />
                        {/* <Column header="Commi. in" field="user_commission_in"  /> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
