import React, { useRef } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from "primereact/toast";

export const FileDemo = () => {

    const toast = useRef(null);

    const onUpload = (e) => {
        readFileContent(e.files[0]).then((textFile) => {
            textFile = textFile.replace(/\r/g, "")
            const headers = textFile.slice(0,textFile.indexOf('\n')).split(',');
            const rows = textFile.slice(textFile.indexOf('\n')+1).split('\n');
            let column_name = ['VoucherNumber','Denomination_Amount']
            if( headers.length > 2){
                toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Column Headers Not Same.', life: 5000 });
                return false 
            };
            for(let i = 0; i < column_name.length; i++){
                if(!headers.includes(column_name[i])){
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Column Headers Not Same.', life: 5000 });
                    return false
                };
            };
        })
    //  toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong.Please try agian later or with differnt file.', life: 5000 });       
       
    }
    const readFileContent = (file) =>{
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = event => resolve(event.target.result);
          reader.onerror = error => reject(error);
          reader.readAsText(file);
        });
      }

    return (
        <div className="grid">
            <div className="col-12">
            <Toast ref={toast} />
                <div className="card">
                    <h5>Advanced</h5>
                    <FileUpload customUpload="true" multiple="false" name="demo[]"uploadHandler={onUpload}  accept=".csv,.xlsx,.xls" maxFileSize={1000000} />

                    {/* <h5>Basic</h5>
                    <FileUpload mode="basic" customUpload="true" name="demo[]"  accept="*" maxFileSize={1000000} uploadHandler={onUpload} /> */}
                </div>
            </div>
        </div>
    )
}
