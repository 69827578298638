import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE } from "./types";

import AuthService from "../../service/auth-service";

export const login = (username,mobile, password,code) => (dispatch) => {
    return AuthService.login(username,mobile, password,code).then(
        (data) => {
            if (data.token) {
                data.country_code = code;
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: data },
                });
            } else {
                dispatch({
                    type: LOGIN_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: data.msg,
                });
                return Promise.reject(data.msg);
            }

            return Promise.resolve();
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject(message);
        }
    );
};

export const logout = () => (dispatch) => {
    // AuthService.logout();

    dispatch({
        type: LOGOUT,
    });
};
