import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "../reducers/index";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const middleware = [thunk];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  || compose;
const authPersistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"],
};

const persistedReducer = persistReducer(authPersistConfig, rootReducer);
const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(...middleware)));
let persister = persistStore(store);

export { store, persister };
