import axios from "axios";

// axios.defaults.baseURL = 'https://paykart.ddns.net:5001/api/'



const login = (username, mobile, password,code) => {
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.get['Accept'] = 'application/json'
    const headers = Buffer.from(username + ";" + mobile + ":" + password, "utf8").toString("base64");

    return axios
        .get("http://89.187.84.162:8002/api/v1/token?country_code="+code, { headers: { Authorization: "Basic " + headers } })
        .then((response) => {
            if (response.data.token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

export default {
    login,
    logout,
};