import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { CustomerService } from "../service/CustomerService";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";



export const Payments = () => {

    const paymenttype = [
        { name: 'Payment', id: '1' },
        { name: 'Refund', id: '2' },
    ];
    const paymenttype1 = [
        { name: 'Payment', id: '1' },
        { name: 'Refund', id: '2' },
        { name: 'Transcation', id: '0' },
    ];
    const statuses = ["0", "1", "2"];
    const [value8, setValue8] = useState({ id: "", name: "" });
    const [paymenttypevalue, setPaymenttypevalue] = useState({ name: 'Payment', id: '1' });
    const [paymenttypevalue1, setPaymenttypevalue1] = useState({ name: 'Payment', id: '1' });
    const toast = useRef(null);
    const [amount, setAmountvalue] = useState("")
    const [desc, setDescvalue] = useState("")
    const [customer, setcustomer] = useState([]);
    const [loading1, setLoading1] = useState(true);
    const [historynew, setHistorynew] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");
    const [filters1, setFilters1] = useState(null);
    const [device, setdevice] = useState([]);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [startdate, setStartdate] = useState(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
    const [enddate, setEnddate] = useState(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));

    const onChangeStartdate = (e) => {
        const startdate = e.target.value;
        setStartdate(startdate);
    };

    const onChangeEnddate = (e) => {
        const enddate = e.target.value;
        setEnddate(enddate);
    };
    const initFilters1 = () => {
        setFilters1({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            username: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            previous_balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            new_balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            payment_type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            comment: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue1("");
    };

    const searchnewlist = () => {
        if (user) {
            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.date = startdate;
            tableItem.enddate = enddate;
            tableItem.payment_type = paymenttypevalue1.id;
            setLoading1(true);
            // console.log(tableItem);
            customerService.getPaymentHistory(tableItem).then((data) => {
                if (isIterable(data)) {
                    setHistorynew(getCustomersnew(data));
                }
                setLoading1(false);
            });

            initFilters1();
        }
    };

    const clearFilter1 = () => {
        initFilters1();
    };
    const representativesItemTemplate = (option) => {
        // console.log(option);
        let info = option.name;
        return (
            <div className="p-multiselect-representative-option">

                {/* <img alt={option.name} src={`assets/demo/images/avatar/${option.image}`} width={32} style={{ verticalAlign: 'middle' }} /> */}
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">

                    {info}
                </span>
            </div>
        );
    };
    const customerService = new CustomerService();

    const onDeviceChange = (e) => {
        let customer_array = [];
        for (let index of customer) {
            if (index.name === e.value.name) {
                customer_array.push(index);
            }
        }

        setValue8(e.value);
    };
    const getCustomersnew = (data) => {
        if (data) {
            return [...(data || [])].map((d) => {
                d.date = new Date(d.date);
                if (d.payment_type === 1) {
                    d.payment_type = "1";
                } else if (d.payment_type === 2) {
                    d.payment_type = "2";
                } else {
                    d.payment_type = "0";
                }
                return d;
            });
        }
    };


    const payments = () => {
        setSubmitted(true)
        if (!value8.id) {
            toast.current.show({ severity: "error", summary: "Please select user.", detail: "User can't be empty.", life: 3000 });
        }
        if (!paymenttypevalue.id) {
            toast.current.show({ severity: "error", summary: "Please select payment type.", detail: "Payment type can't be empty.", life: 3000 });
        }
        if (amount <= 0) {
            toast.current.show({ severity: "error", summary: "Please input valid amount.", detail: "Amount can't be less than or equal to zero.", life: 5000 });
        }
        if (paymenttypevalue.id && amount && value8.id && desc) {
            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.user_id = value8.id;
            tableItem.amount = amount;
            tableItem.desc = desc;
            tableItem.payment_type = paymenttypevalue.id;

            customerService.addCustPayment(tableItem).then((data) => {
                // console.log(data);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Payment Added", life: 3000 });
                searchnewlist()
            }).catch((error) => {
                toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
            });
        }
    };


    const renderHeader1 = () => {
        return (
            <div>
                <div className="flex justify-content-between  card border-1 surface-border  flex-column sm:flex-row mb-2">
                    <Calendar value={startdate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeStartdate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
                    <Calendar value={enddate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeEnddate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
                    <Dropdown id="typedropdown" value={paymenttypevalue1} options={paymenttype1} onChange={(e) => setPaymenttypevalue1(e.value)} optionLabel="name" className="p-button-outlined mb-2" />
                    <Button type="button" icon="pi pi-search" label="Search" className="p-button-outlined mb-2" onClick={searchnewlist} />
                    {/* <Button label="Export" icon="pi pi-upload" className="p-button-help " onClick={exportCSV} /> */}
                </div>
                <div className="flex justify-content-between flex-column sm:flex-row ">
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined mb-2" onClick={clearFilter1} />
                    <Button type="button" icon="pi pi-refresh" label="Refresh" className="p-button-outlined mb-2" onClick={searchnewlist} />
                    <span className="p-input-icon-left mb-2">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" style={{ width: "100%" }} />
                    </span>
                </div>
            </div>
        );
    };

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1["global"].value = value;

        setFilters1(_filters1);
        // console.log(value);
        setGlobalFilterValue1(value);
    };

    const formatDate = (value) => {
        var dateA = new Date(value);
        var str = dateA.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
        return str.replace(/,/, " ");
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "AED" });
    };
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.amount);
    };

    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="AED" locale="en-US" />;
    };
    const prebalanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.previous_balance);
    };

    const prebalanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="AED" locale="en-US" />;
    };
    const newbalanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.new_balance);
    };

    const newbalanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="AED" locale="en-US" />;
    };


    const statusBodyTemplate = (rowData) => {
        if (rowData.payment_type === "0") {
            return (
                <span className={`customer-badge status-unqualified`}>
                    Transaction
                </span>
            );
        } else if (rowData.payment_type === "2") {
            return (
                <span className={`customer-badge status-qualified`}>
                    Refund
                </span>
            );
        } else {
            return (
                <span className={`customer-badge status-proposal`}>
                    Payment
                </span>
            );
        }
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a type" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        if (option === "0") {
            return <span className={`customer-badge status-unqualified`}>transcation</span>;
        } else if (option === "2") {
            return <span className={`customer-badge status-qualified`}>refund</span>;
        } else {
            return <span className={`customer-badge status-proposal`}>payment</span>;
        }
    };

    useEffect(() => {
        let tableItem = {};
        tableItem.token = user.token;
        tableItem.role = user.user.role;
        tableItem.id = user.user.id;
        tableItem.date = startdate;
        tableItem.enddate = enddate;
        tableItem.payment_type = paymenttypevalue1.id;
        // console.log(tableItem);

        customerService.getCustomers(tableItem).then((data) => {
            if (isIterable(data)) {
                let items = [];
                for (let index of data[0]) {
                    let list = {};
                    list["id"] = index.id;
                    list["name"] = index.username + " (" + index.role + " )";
                    items.push(list);
                }
                setdevice(items);
                // setcustomer(items);
            }
        });

        setLoading1(true);
        // console.log(tableItem);
        customerService.getPaymentHistory(tableItem).then((data) => {
            if (isIterable(data)) {
                setHistorynew(getCustomersnew(data));
            }
            setLoading1(false);
        });

        initFilters1();


    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const isIterable = (obj) => {
        // checks for null and undefined
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    }
    const header1 = renderHeader1();
    if (!isLoggedIn) {
        // window.location.reload(false);
        return <Redirect to="/" />;
    }
    return (
        <div className="grid">
            <div className="col-12">
                {user.user.role === 'admin' && <div className="card">
                    <h5> Payments </h5>
                    <div className="grid table-demo">
                        <Toast ref={toast} />
                        <div className="col-12">
                            <div className="card p-fluid">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="dropdown"> Users </label> <Dropdown id="dropdown" value={value8} options={device} itemTemplate={representativesItemTemplate} onChange={(e) => onDeviceChange(e)} optionLabel="name" placeholder="select User" className="p-column-filter" />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="typedropdown"> Payment Type </label> <Dropdown id="typedropdown" value={paymenttypevalue} options={paymenttype} onChange={(e) => setPaymenttypevalue(e.value)} optionLabel="name" className="p-column-filter" />
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="amount">Amount</label>
                                        <InputNumber id="amount" value={amount} onChange={(e) => setAmountvalue(e.value)} required className={classNames({ "p-invalid": submitted && !amount })} />
                                        {submitted && !amount && <small className="p-invalid">Amount is required.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="desc">Desc.</label>
                                        <InputText id="desc" value={desc} onChange={(e) => setDescvalue(e.target.value)} required className={classNames({ "p-invalid": submitted && !desc })} />
                                        {submitted && !desc && <small className="p-invalid">Description is required.</small>}
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-10"> </div>
                                    <div className="field col">
                                        <Button label="Submit" onClick={payments}>

                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <DataTable
                    // ref={dt}
                    value={historynew}
                    paginator
                    className="p-datatable-gridlines datatable-responsive"
                    showGridlines
                    rows={15}
                    rowsPerPageOptions={[10, 15, 50]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                    sortMode="single"
                    sortField={["username", "date", "amount", "previous_balance", "new_balance", "payment_type", "comment"]}
                    sortOrder={1}
                    dataKey="id"
                    filters={filters1}
                    filterDisplay="menu"
                    loading={loading1}
                    globalFilterFields={["username", "date", "amount", "previous_balance", "new_balance", "payment_type", "comment"]}
                    header={header1}
                    emptyMessage="No History found."
                >
                    {/* <Column sortable field="transcation_id" header="Trans. Id" filter filterPlaceholder="Search by Trans. Id" /> */}
                    <Column sortable field="username" header="User" filter filterPlaceholder="Search by User" />
                    {/* <Column sortable field="device" header="Device" filter filterPlaceholder="Search by Device" /> */}
                    {/* <Column sortable field="number" header="Number" filter filterPlaceholder="Search by Number" /> */}
                    <Column header="Date" filterField="date" dataType="date" body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
                    <Column sortable header="Amount" filterField="amount" dataType="numeric" body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
                    <Column sortable header="Pre. Bal." filterField="previous_balance" dataType="numeric" body={prebalanceBodyTemplate} filter filterElement={prebalanceFilterTemplate} />
                    <Column sortable header="New Bal." filterField="new_balance" dataType="numeric" body={newbalanceBodyTemplate} filter filterElement={newbalanceFilterTemplate} />
                    <Column sortable field="payment_type" header="Type" filterMenuStyle={{ width: "14rem" }} body={statusBodyTemplate} filter filterElement={statusFilterTemplate} />
                    <Column header="Desc." field="comment" filter filterPlaceholder="Search by Comments" />
                    {/* <Column body={actionBodyTemplate}></Column> */}
                </DataTable>
            </div>
        </div>
    );
};
