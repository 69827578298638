import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { CustomerService } from "../service/CustomerService";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";

export const AmzVoucher = () => {
    const [displayBasic, setDisplayBasic] = useState(false);
    const basicDialogFooter = <Button type="button" label="Dismiss" onClick={() => setDisplayBasic(false)} icon="pi pi-check" className="p-button-secondary" />;
    const statustype = [
        { id: "1", name: "Pending" },
        { id: "2", name: "Success" },
        { id: "0", name: "Failed" },
    ];
    const toast = useRef(null);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [historynew, setHistorynew] = useState(null);
    const [singelrowData, setSingelrowData] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [filters1, setFilters1] = useState(null);
    const [value8, setValue8] = useState({ id: null, name: null });
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");
    const [loading1, setLoading1] = useState(true);
    const [response, setResponse] = useState(true);
    const [startdate, setStartdate] = useState(new Date((Date.now() - (7*24*60*60*1000)) - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
    const [enddate, setEnddate] = useState(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
    const onChangeStartdate = (e) => {
        const startdate = e.target.value;
        setStartdate(startdate);
    };

    const onUpload = (e) => {
        
        readFileContent(e.files[0]).then((textFile) => {
            textFile = textFile.replace(/\r/g, "");
            const headers = textFile.slice(0, textFile.indexOf("\n")).split(",");
            // const rows = textFile.slice(textFile.indexOf("\n") + 1).split("\n");
            let column_name = ["VoucherNumber", "Denomination_Amount"];
            if (headers.length >  column_name.length) {
                toast.current.show({ severity: "error", summary: "Failed", detail: "Column Headers Not Same.", life: 5000 });
                return false;
            }
            for (let i = 0; i < column_name.length; i++) {
                if (!headers.includes(column_name[i])) {
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Column Headers Not Same.", life: 5000 });
                    return false;
                }
            }
            // submitFile(e.files[0]);
            
        });
        //  toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong.Please try agian later or with differnt file.', life: 5000 });
    };
    const readFileContent = (file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    };

    const submitFile = (file) => {
        let tableItem = new FormData();
        tableItem.token = user.token;
        tableItem.append("productName", "Node.js Stickers");
        tableItem.append("file", file, file.name);
        customerService
            .saveamzvoucher(tableItem)
            .then((data) => {
                searchnewlist()
                toast.current.show({ severity: "info", summary: "Response", detail: data.msg, life: 5000 });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 5000 });
            });
    };

    const exportCSV = () => {
        // Headers for each column
        let headers = ["agent_ID,created_date,denomination,id,modified_date,status,used_by,voucher_number"];

        // Convert users data to a csv

        let usersCsv = historynew.reduce((acc, detail) => {
            // if (detail.status === "0") {
            //     detail.status = "Failed";
            //     var str = detail.date.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
            //     detail.date = str.replace(/,/, " ");
            // } else if (detail.status === "2") {
            //     detail.status = "Success";
            //     var str2 = detail.date.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
            //     detail.date = str2.replace(/,/, " ");
            // } else {
            //     detail.status = "Pending";
            //     var str1 = detail.date.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
            //     detail.date = str1.replace(/,/, " ");
            // }

            detail.created_date = new Date(detail.created_date);
            var str1 = detail.created_date.toLocaleString("en-GB", { timeZone: "Asia/Dubai" });
            detail.created_date = str1.replace(/,/, " ");
            if(detail.modified_date){
            detail.modified_date = new Date(detail.modified_date);
            var str1 = detail.modified_date.toLocaleString("en-GB", { timeZone: "Asia/Dubai" });
            detail.modified_date = str1.replace(/,/, " ");}
            const { agent_ID, created_date, denomination, id, modified_date, status, used_by, voucher_number } = detail;
            acc.push([agent_ID, created_date, denomination, id, modified_date, status, used_by, voucher_number].join(","));
            return acc;
        }, []);

        downloadFile({
            data: [...headers, ...usersCsv].join("\n"),
            fileName: "Amz_Voucher_history.csv",
            fileType: "text/csv",
        });
    };
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });

        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    const onChangeEnddate = (e) => {
        const enddate = e.target.value;
        setEnddate(enddate);
    };

    const statuses = ["0", "1", "2"];
    // let startdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    // let enddate = (new Date(Date.now() - new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    const customerService = new CustomerService();

    useEffect(() => {
        if (user) {
            setLoading1(true);

            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.date = startdate;
            tableItem.enddate = enddate;
            // console.log(tableItem);
            customerService.getAmzVoucherHistory(tableItem).then((data) => {
                if (isIterable(data)) {
                    setHistorynew(getCustomersnew(data));
                }
                setLoading1(false);
            });

            initFilters1();
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    const isIterable = (obj) => {
        // checks for null and undefined
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === "function";
    };

    const hideDialog = () => {
        // setSubmitted(false);
        setProductDialog(false);
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < historynew.length; i++) {
            if (historynew[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };
    const saveProduct = () => {
        if (!value8.id) {
            toast.current.show({ severity: "error", summary: "Please select status.", detail: "Status can't be empty.", life: 3000 });
        }
        const customerService = new CustomerService();

        if (value8.id && singelrowData.id) {
            let _products = [...historynew];
            let _product = { ...singelrowData };
            let tableItem = {};
            tableItem.id = _product.id;
            tableItem.status = value8.id;
            tableItem.token = user.token;
            customerService
                .geteditorderinfo(tableItem)
                .then((data) => {
                    setProductDialog(false);
                    const index = findIndexById(_product.id);
                    // alert(_product.status);
                    _product.status = value8.id;
                    // alert(_product.status);
                    _products[index] = _product;

                    toast.current.show({ severity: "success", summary: "Successful", detail: "Order Updated", life: 3000 });
                })
                .catch((error) => {
                    setProductDialog(false);
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
                });
            setHistorynew(_products);
            setProductDialog(false);
            setLoading1(true);
            searchnewlist();
        }
    };
    const searchnewlist = () => {
        if (user) {
            let date1 = new Date(startdate);
            let date2 = new Date(enddate);
            if (date2 < date1) {
                toast.current.show({ severity: "error", summary: "End Date Should Always Greater Or Equal To Start Date!", detail: "", life: 5000 });
                return false;
            }

            setLoading1(true);

            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.date = startdate;
            tableItem.enddate = enddate;
            customerService.getAmzVoucherHistory(tableItem).then((data) => {
                setHistorynew(getCustomersnew(data));
                setLoading1(false);
            });

            initFilters1();
        }
    };

    const getCustomersnew = (data) => {
        if (data) {
            return [...(data || [])].map((d) => {
                d.date = new Date(d.created_date);
                if (d.modified_date) {
                    d.modified_date = new Date(d.modified_date);
                }
                d.mask_voucher = getstarred(d.voucher_number);
                if (d.used_by) {
                    d.status = "2";
                } else {
                    d.status = "0";
                }
                return d;
            });
        }
    };
    const getstarred = (str) => {
        let len = str.length;

        return str.substr(0, 4) + "*".repeat(len - 8) + str.substr(len - 4, 4);
    };
    const formatDate = (value) => {
        var dateA = new Date(value);
        var str = dateA.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
        return str.replace(/,/, " ");
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "AED" });
    };
    const formatdata = (value) => {
        if (!value) {
            return <span className={`customer-badge status-unqualified`}>Not-Used</span>;
        } else {
            return <span className={`customer-badge status-qualified`}>{value}</span>;
        }
    };

    const clearFilter1 = () => {
        initFilters1();
    };

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1["global"].value = value;

        setFilters1(_filters1);
        // console.log(value);
        setGlobalFilterValue1(value);
    };

    const initFilters1 = () => {
        setFilters1({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            mask_voucher: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            denomination: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            used_by: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            agent_ID: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            modified_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchModematchMode: FilterMatchMode.DATE_IS }] },
            // order_id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue1("");
    };

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span style={{ textAlign: "center", color: "purple" }} className="text-bold pr-6">
                    <b>Total Unused Cards:-{calculateTotalrecharges(historynew)}</b>
                </span>
                <span style={{ textAlign: "center", color: "green" }} className="text-bold pr-6">
                    <b>Total Unused Amount :-{calculateTotalsuccess(historynew)}</b>
                </span>
            </React.Fragment>
        );
    };

    const calculateTotalsuccess = (historynew) => {
        let total = 0;
        if (historynew) {
            for (let data of historynew) {
                if (!data.used_by) {
                    total += data.denomination;
                }
            }
        }
        return 'Total Unused Amount :-' + total;
    };
    const calculateTotalrecharges = (historynew) => {
        let total = 0;
        if (historynew) {
            for (let data of historynew) {
                if (!data.used_by) {
                    total++;
                }
            }
        }
        return 'Total Unused Cards:-'+total;
    };
    const renderHeader1 = () => {
        return (
            <div>
                <div className="flex justify-content-between  card border-1 surface-border  flex-column sm:flex-row mb-2">
                    <Calendar value={startdate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeStartdate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
                    <Calendar value={enddate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeEnddate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
                    <Button type="button" icon="pi pi-search" label="Search" className="p-button-outlined mb-2" onClick={searchnewlist} />
                    <Button label="Export" icon="pi pi-upload" className="p-button-help " onClick={exportCSV} />
                </div>
                <div className="flex justify-content-between flex-column sm:flex-row ">
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined mb-2" onClick={clearFilter1} />
                    <Button type="button" icon="pi pi-refresh" label="Refresh" className="p-button-outlined mb-2" onClick={searchnewlist} />

                    <span className="p-input-icon-left mb-2">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" style={{ width: "100%" }} />
                    </span>
                </div>
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yyyy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };
    const useddateBodyTemplate = (rowData) => {
        if (rowData.modified_date) return formatDate(rowData.modified_date);
        else return rowData.modified_date;
    };

    const useddateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yyyy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.denomination);
    };
    const usedbyBodyTemplate = (rowData) => {
        return formatdata(rowData.used_by);
    };
    const agentidBodyTemplate = (rowData) => {
        return formatdata(rowData.agent_ID);
    };
    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="AED" locale="en-US" />;
    };
    const usedbyFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} />;
    };
    const agentidFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} />;
    };

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const header1 = renderHeader1();
    if (!isLoggedIn) {
        return <Redirect to="/" />;
    }
    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <h5>
                        Upload Voucher{" "}
                        <a href="./csv-sample-amz.csv" download>
                            (Sample CSV)
                        </a>
                    </h5>
                    <FileUpload customUpload={true} multiple={false} name="demo[]" uploadHandler={onUpload} accept=".csv,.xlsx,.xls" maxFileSize={1000000} />

                    {/* <h5>Basic</h5>
                    <FileUpload mode="basic" customUpload="true" name="demo[]"  accept="*" maxFileSize={1000000} uploadHandler={onUpload} /> */}
                </div>
            </div>
            <Toast ref={toast} />
            <Dialog header="Message" visible={displayBasic} style={{ width: "450px" }} className="p-fluid" modal footer={basicDialogFooter} onHide={() => setDisplayBasic(false)}>
                <p>{response}</p>
            </Dialog>
            <Dialog visible={productDialog} style={{ width: "450px" }} header="Status Info" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                <div className="formgrid grid">
                    <div className="col-12 col">
                        {/* <label htmlFor="dropdown">Status</label> */}
                        <Dropdown id="dropdown" options={statustype} value={value8} onChange={(e) => setValue8(e.value)} optionLabel="name" placeholder="Select Status" className="p-column-filter"></Dropdown>
                    </div>
                </div>
            </Dialog>
            <div className="col-12">
                <div className="card">
                    <h5>Vouchers List</h5>
                    <div className="formgrid grid">
                        <div className="col-12 col">
                            <div className="flex align-items-center flex-column sm:flex-row">
                                <Button type="button" label={calculateTotalsuccess(historynew)} className="mr-2"></Button>
                                <Button type="button" label={calculateTotalrecharges(historynew)} className="mr-2 p-button-warning"></Button>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        // ref={dt}
                        value={historynew}
                        paginator
                        className="p-datatable-gridlines datatable-responsive"
                        showGridlines
                        rows={15}
                        // rowGroupMode="subheader"
                        // rowGroupHeaderTemplate={headerTemplate}
                        rowsPerPageOptions={[10, 15, 50]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        sortMode="single"
                        sortField={["id", "denomination", "mask_voucher", "modified_date", "used_by", "agent_ID", "date"]}
                        sortOrder={1}
                        dataKey="id"
                        filters={filters1}
                        filterDisplay="menu"
                        loading={loading1}
                        globalFilterFields={["id", "denomination", "mask_voucher", "modified_date", "used_by", "agent_ID", "date"]}
                        header={header1}
                        emptyMessage="No History found."
                    >
                        <Column sortable field="id" header="Id" filter filterPlaceholder="Search by Id" />
                        <Column sortable field="mask_voucher" header="voucher number" filter filterPlaceholder="Search by voucher number" />
                        <Column sortable header="Date" filterField="date" dataType="date" body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
                        <Column sortable header="Used Date" filterField="modified_date" dataType="date" body={useddateBodyTemplate} filter filterElement={useddateFilterTemplate} />
                        <Column sortable header="Amount" filterField="denomination" dataType="numeric" body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
                        <Column sortable header="Used By" filterField="used_by" dataType="numeric" body={usedbyBodyTemplate} filter filterElement={usedbyFilterTemplate} />
                        <Column header="Agent ID" filterField="agent_ID" dataType="numeric" body={agentidBodyTemplate} filter filterElement={agentidFilterTemplate} />
                        {/* <Column sortable field="status" header="Used By" filterMenuStyle={{ width: "14rem" }} body={statusBodyTemplate} filter filterElement={statusFilterTemplate} /> */}
                        {/* <Column sortable header="Order Id" field="order_id" filter filterPlaceholder="Search by Order. Id" /> */}
                        {/* <Column body={actionBodyTemplate}></Column> */}
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
