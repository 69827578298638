import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import classNames from "classnames";
import { CustomerService } from "../service/CustomerService";

export const Recharge = () => {
    const [amount, setAmount] = useState("");
    const [timeout, setTimeout] = useState("");
    const [number, setNumber] = useState("");
    const [value8, setValue8] = useState("");
    const [operatortypevalue,setOperatortypevalue] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const customerService = new CustomerService();

    const rechargetype = [
        { id: 1, name: "More Time" },
        { id: 5, name: "More Credit" },
        { id: 8, name: "More International" },
        { id: 9, name: "More Data" },
    ];
    const operatortype =[
        { id: 1, name: "Du" },
        { id: 2, name: "Etisalat" },
    ]
    const onAmountChange = (e) => {
        setAmount(e.target.value);
    };
    const onTimeoutChange = (e) => {
        setTimeout(e.target.value);
    };
    const onNumberChange = (e) => {
        setNumber(e.target.value);
    };
    const transferbalance = () => {
        setSubmitted(true);
        setLoading(true);
        if (!value8.id) {
            setLoading(false);
            toast.current.show({ severity: "error", summary: "Please select recharge type.", detail: "", life: 3000 });
        }
        if (!operatortypevalue.id){
            setLoading(false);
            toast.current.show({ severity: "error", summary: "Please select operator type.", detail: "", life: 3000 });
        }
        if (number && timeout && amount && operatortypevalue.id) {
            setSubmitted(false);
            let tableItem = {};
            tableItem.number = number;
            tableItem.amount = amount;
            tableItem.recharge_type = value8.id;
            tableItem.operator_code = operatortypevalue.id;
            tableItem.timeout = timeout;
            tableItem.token = user.token;
            customerService
                .simplerecharge(tableItem)
                .then((data) => {
                    setLoading(false);
                    if(data.code === 0 && data.status === 'success')
                    {
                        toast.current.show({ severity: "success", summary: data.transaction_id, detail: data.message, life: 10000 });
                    }
                    else{
                        toast.current.show({ severity: "info", summary: data.transaction_id, detail: data.message, life: 10000 });
                    }

                                   })
                .catch((error) => {
                    setLoading(false);
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 10000 });
                });
            

        }
    };

    useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!isLoggedIn) {
        return <Redirect to="/" />;
    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5> Recharge Number </h5>
                    <div className="grid table-demo">
                        <Toast ref={toast} />
                        <div className="col-12">
                            <div className="card p-fluid">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="number"> Mobile Number </label> <InputText id="number" value={number} onChange={(e) => onNumberChange(e)} type="text" required className={classNames({ "p-invalid": submitted && !number })} />
                                        {submitted && !number && <small className="p-invalid"> Mobile Number is required. </small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="timeout"> Timeout </label> <InputText id="timeout" value={timeout} onChange={(e) => onTimeoutChange(e)} type="text" required className={classNames({ "p-invalid": submitted && !timeout })} />
                                        {submitted && !timeout && <small className="p-invalid"> Timeout is required. </small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="dropdown"> Recharge Type </label>
                                        <Dropdown id="dropdown" options={rechargetype} value={value8} onChange={(e) => setValue8(e.value)} optionLabel="name" placeholder="Select Recharge Type" className="p-column-filter">
                                            
                                        </Dropdown>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="dropdown"> Operator Type </label>
                                        <Dropdown id="dropdown" options={operatortype} value={operatortypevalue} onChange={(e) => setOperatortypevalue(e.value)} optionLabel="name" placeholder="Select Operator Type" className="p-column-filter">
                                            
                                        </Dropdown>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="amount"> Amount </label> <InputText id="amount" value={amount} onChange={(e) => onAmountChange(e)} type="text" required className={classNames({ "p-invalid": submitted && !amount })} />
                                        {submitted && !amount && <small className="p-invalid"> Amount is required. </small>}
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-10"> </div>
                                    <div className="field col">
                                        <Button label="Recharge" icon={!loading ? "pi pi-tablet" : "pi pi-spin pi-spinner"}  disabled={loading} onClick={transferbalance}>
                                            
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
