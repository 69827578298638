import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { CustomerService } from "../service/CustomerService";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Redirect } from "react-router-dom";
export const Simlist = () => {
    let emptyProduct = {
        agent_ID: "",
        agent_pin: "",
        id: "",
        name: "",
        port: "",
        sim_type: 0,
        status: 0,
        urls: "",
        user_id: "",
        operator_type: 1,
    };

    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [products, setProducts] = useState(null);
    const [value8, setValue8] = useState({ id: "", name: "" });
    const [customer, setcustomer] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [loading1, setLoading1] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Idarray, setIdarray] = useState([]);
    const toast = useRef(null);
    const statuses = ["0", "1"];
    const operators = ["1", "2"];
    const [radioValue, setRadioValue] = useState("0");
    const [simtypeValue, setSimtypeValue] = useState("0");
    const [OptypeValue,setOptypeValue] = useState("0");
    useEffect(() => {
        const customerService = new CustomerService();

        setLoading1(true);
        if (user) {
            let tableItem = {};
            tableItem.token = user.token;
            customerService.getCustomers(tableItem).then((data) => {
                if (isIterable(data)) {
                let items = [];
                let idarr =[];
                for (let index of data[0]) {
                    let list = {};
                    list["id"] = index.id;
                    list["name"] = index.username + "(" + index.role + " )";
                    items.push(list);
                    idarr.push(index.id);
                }
                setcustomer(items);
                setIdarray(idarr);
            }
            });
            customerService.getSimList(tableItem).then((data) => {
                if (isIterable(data)) {
                setProducts(data);
                setLoading1(false);
                }
            });
        }
    }, [user]);
    const isIterable= (obj) => {
        // checks for null and undefined
        if (obj == null) {
          return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
      }
    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const saveProduct = () => {
        setSubmitted(true);
        if (!value8.id) {
            toast.current.show({ severity: "error", summary: "Please select customer.", detail: "Customer can't be empty.", life: 3000 });
        }
        const customerService = new CustomerService();

        if (product.name.trim() && product.urls.trim() && product.port && product.agent_ID.trim() && product.agent_pin.trim() && value8.id) {
            let _products = [...products];

            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _product.user_id = value8.id;
                _product.status = radioValue;
                _product.sim_type = simtypeValue;
                _product.operator_type = OptypeValue;
                _products[index] = _product;

                let tableItem = {};
                tableItem = _product;
                tableItem.token = user.token;

                // console.log(tableItem);
                customerService.geteditdeviceinfo(tableItem).then((data) => {
                    // console.log(data);
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Device Updated", life: 3000 });
                }).catch((error) => {
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
                });
                
                
            } else {
                
                _product.id = _product.port;
                _product.user_id = value8.id;
                _product.status = radioValue;
                _product.sim_type = simtypeValue;
                _product.operator_type = OptypeValue;
                let tableItem = {};
                tableItem = _product;
                tableItem.token = user.token;
                
                customerService.geteditdeviceinfo(tableItem).then((data) => {
                    // console.log(data)
                    if(!data.port)
                    {
                        toast.current.show({ severity: "error", summary: data.msg, detail: "", life: 5000 });
                    }else{
                        _products.push(data);
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Device Created", life: 3000 });
                    
                    }
                    }).catch((error) => {
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
                });
              
            }

            setProducts(_products);
            setProductDialog(false);
            setProduct(emptyProduct);
        }
    };

    const editProduct = (product) => {
        for (let index of customer) {
            if (index.id === product.user_id) {
                setValue8(index);
            }
        }
        setRadioValue(product.status);
        setSimtypeValue(product.sim_type)
        setOptypeValue(product.operator_type)
        setProduct({ ...product });
        setProductDialog(true);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.status == "0") {
            return <span className={`product-badge status-outofstock`}>In-Active</span>;
        } else {
            return <span className={`product-badge status-instock`}>Active</span>;
        }
    };
    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        if (option === "0") {
            return <span className={`product-badge status-outofstock`}>In-Active</span>;
        } else {
            return <span className={`product-badge status-instock`}>Active</span>;
        }
    };
    const assignBodyTemplate = (rowData) => {
        let dataassign = "";
        for (let index of customer) {
            if (index.id == rowData.user_id) {
                dataassign = <span className={`product-badge status-lowstock`}>{index.name}</span>;
            }
        }

        return dataassign;
    };
    const assignFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={Idarray} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={assignItemTemplate} placeholder="Select a user" className="p-column-filter" showClear />;
    };

    const assignItemTemplate = (option) => {
        let dataassign = "";
        for (let index of customer) {
            if (index.id == option) {
                dataassign = <span className={`product-badge status-lowstock`}>{index.name}</span>;
            }
        }

        return dataassign;
    };
    const representativesItemTemplate = (option) => {
        let info = option.name;
        return (
            <div className="p-multiselect-representative-option">
                {/* <img alt={option.name} src={`assets/demo/images/avatar/${option.image}`} width={32} style={{ verticalAlign: 'middle' }} /> */}
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">
                    {info}
                </span>
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    };
    const allowrechargesBodyTemplate    = (rowData) => {
     
        if (rowData.operator_type == "0") {
            return <span >Both(Du, Etisalat)</span>;
        } else if (rowData.operator_type == "1") {
            return <span >Du</span>;
        }else {
            return <span >Etisalat</span>;
        }
    };

    const allowrechargesFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={operators} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={allowrechargesItemTemplate} placeholder="Select a user" className="p-column-filter" showClear />;
    };

    const allowrechargesItemTemplate = (option) => {
        if (option == "0") {
            return <span >Both(Du, Etisalat)</span>;
        } else if (option == "1") {
            return <span >Du</span>;
        }else {
            return <span >Etisalat</span>;
        }
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Device</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    if (!isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Device Info</h5>
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        value={products}
                        loading={loading1}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        globalFilter={globalFilter}
                        emptyMessage="No products found."
                        header={header}
                    >
                        <Column field="agent_ID" header="Agent ID" ></Column>
                        <Column field="name" header="Device Name" sortable></Column>
                        <Column field="port" header="Port No" sortable></Column>
                        <Column field="urls" header="Url" ></Column>
                        <Column field="user_id" header="Assign" body={assignBodyTemplate} dataType="numeric" sortable filter filterElement={assignFilterTemplate}></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate} dataType="numeric" sortable filter filterElement={statusFilterTemplate}></Column>
                        <Column field="operator_type" header="Operator" dataType="numeric" body={allowrechargesBodyTemplate} sortable filter filterElement={allowrechargesFilterTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "450px" }} header="Device Info" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, "name")} required autoFocus className={classNames({ "p-invalid": submitted && !product.name })} />
                            {submitted && !product.name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="urls">Url</label>
                            <InputTextarea id="urls" value={product.urls} onChange={(e) => onInputChange(e, "urls")} required rows={2} cols={20} className={classNames({ "p-invalid": submitted && !product.urls })} />
                            {submitted && !product.urls && <small className="p-invalid">url is required.</small>}
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="port">Port</label>
                                <InputText id="port" value={product.port} onChange={(e) => onInputChange(e, "port")} required className={classNames({ "p-invalid": submitted && !product.port })} />
                                {submitted && !product.port && <small className="p-invalid">Port is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="dropdown">Assign Customer</label>
                                <Dropdown id="dropdown" value={value8} options={customer} itemTemplate={representativesItemTemplate} onChange={(e) => setValue8(e.value)} optionLabel="name" placeholder="select customer" className="p-column-filter" />
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="agent_ID">Agent ID</label>
                                <InputText id="agent_ID" value={product.agent_ID} onChange={(e) => onInputChange(e, "agent_ID")} required className={classNames({ "p-invalid": submitted && !product.agent_ID })} />
                                {submitted && !product.agent_ID && <small className="p-invalid">Agent ID is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="agent_pin">Agent Pin</label>
                                <InputText id="agent_pin" value={product.agent_pin} onChange={(e) => onInputChange(e, "agent_pin")} required className={classNames({ "p-invalid": submitted && !product.agent_pin })} />
                                {submitted && !product.agent_pin && <small className="p-invalid">Agent Pin is required.</small>}
                            </div>
                        </div>
                        {/* <h6>Status</h6> */}
                        <div className="formgrid grid">
                            <label>SimType :</label>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option1" name="option" value="0" checked={simtypeValue == "0"} onChange={(e) => setSimtypeValue(e.value)} />
                                    <label htmlFor="option1">Mpos</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value="1" checked={simtypeValue == "1"} onChange={(e) => setSimtypeValue(e.value)} />
                                    <label htmlFor="option2">Normal</label>
                                </div>
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <label>Operator</label>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option1" name="option" value="1" checked={OptypeValue == "1"} onChange={(e) => setOptypeValue(e.value)} />
                                    <label htmlFor="option1">Du</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value="2" checked={OptypeValue == "2"} onChange={(e) => setOptypeValue(e.value)} />
                                    <label htmlFor="option2">Etisalat</label>
                                </div>
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <label>Status  :-</label>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option1" name="option" value="0" checked={radioValue == "0"} onChange={(e) => setRadioValue(e.value)} />
                                    <label htmlFor="option1">In-active</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value="1" checked={radioValue == "1"} onChange={(e) => setRadioValue(e.value)} />
                                    <label htmlFor="option2">Active</label>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
