import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import { CustomerService } from "../service/CustomerService";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dialog } from "primereact/dialog";
import classNames from "classnames";
import { Divider } from 'primereact/divider';

export const BalanceTransfer = () => {
    const [displayBasic, setDisplayBasic] = useState(false);
    const basicDialogFooter = <Button type="button" label="Dismiss" onClick={() => setDisplayBasic(false)} icon="pi pi-check" className="p-button-secondary" />;
    const [value8, setValue8] = useState({ id: "", name: "" });
    const [fromport, setFromPort] = useState({ port: "", name: "" });
    const [toport, setToport] = useState({ port: "", name: "" });
    const [amount, setAmount] = useState('');
    const toast = useRef(null);

    const [customer, setcustomer] = useState([]);
    const [dropcustomer, setdropcustomer] = useState([]);
    const [device, setdevice] = useState([]);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [historynew, setHistorynew] = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");
    const [loading1, setLoading1] = useState(true);
    const [response, setResponse] = useState(true);
    const [startdate, setStartdate] = useState(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
    const [enddate, setEnddate] = useState(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
    const [submitted, setSubmitted] = useState(false);
    const onChangeStartdate = (e) => {
        const startdate = e.target.value;
        setStartdate(startdate);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <div className="actions">
                    <Button icon="pi pi-ellipsis-v" className="p-button-rounded p-button-warning" onClick={() => showresponse(rowData)} />
                </div>
            </div>
        );
    };
    const showresponse = (rowData) => {
        setResponse(rowData.response);
        setDisplayBasic(true);
    };

    const exportCSV = () => {
        // Headers for each column
        let headers = ["id,device_name,serial,date,amount,from_port,to_port"];

        // Convert users data to a csv

        let usersCsv = historynew.reduce((acc, detail) => {
            detail.date = new Date(detail.date)
            var str1 = detail.date.toLocaleString("en-GB", { timeZone: "Asia/Dubai" });
            detail.date = str1.replace(/,/, " ");

            const { id, device_name,serial, date, amount, from_port, to_port } = detail;
            acc.push([id, device_name,serial, date, amount, from_port, to_port].join(","));
            return acc;
        }, []);

        downloadFile({
            data: [...headers, ...usersCsv].join("\n"),
            fileName: "sim_recharge_history.csv",
            fileType: "text/csv",
        });
    };

    const representativesItemTemplate = (option) => {
        // console.log(option);
        let info = option.name;
        return (
            <div className="p-multiselect-representative-option">
                {/* <img alt={option.name} src={`assets/demo/images/avatar/${option.image}`} width={32} style={{ verticalAlign: 'middle' }} /> */}
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">
                    {info}
                </span>
            </div>
        );
    };
    const portItemTemplate = (option) => {
        let info = option.port;

        return (
            <div className="p-multiselect-representative-option">
                {/* <img alt={option.name} src={`assets/demo/images/avatar/${option.image}`} width={32} style={{ verticalAlign: 'middle' }} /> */}
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }} className="image-text">
                    {info}
                </span>
            </div>
        );
    };

    const onDeviceChange = (e) => {
        let customer_array = [];
        for (let index of customer) {
            if (index.name === e.value.name) {
                customer_array.push(index);
            }
        }

        setdropcustomer(customer_array);
        setValue8(e.value);
        setFromPort({ port: "", name: "" });
        setToport({ port: "", name: "" });
    };

    const onFromPortChange = (e) => {
        setFromPort(e.value);
    };
    const onAmountChange = (e) => {
        setAmount(e.target.value);
    };
    const onToPortChange = (e) => {
        setToport(e.value);
    };
    const transferbalance = () => {
        setSubmitted(true);
        if (!value8.id) {
            toast.current.show({ severity: "error", summary: "Please select device.", detail: "Device can't be empty.", life: 3000 });
        }
        if (!fromport.port) {
            toast.current.show({ severity: "error", summary: "Please select from port.", detail: "From Port can't be empty.", life: 3000 });
        }
        if (!toport.port) {
            toast.current.show({ severity: "error", summary: "Please select to port.", detail: "To Port can't be empty.", life: 3000 });
        }
        if (toport.port && fromport.port && amount && value8.id) {
            setSubmitted(false);
        }
    };
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });

        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    const onChangeEnddate = (e) => {
        const enddate = e.target.value;
        setEnddate(enddate);
    };

    // let startdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    // let enddate = (new Date(Date.now() - new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    const customerService = new CustomerService();

    useEffect(() => {
        // if(user){
        setLoading1(true);
        
        let tableItem = {};
        tableItem.token = user.token;
        tableItem.role = user.user.role;
        tableItem.id = user.user.id;
        tableItem.date = startdate;
        tableItem.enddate = enddate;
        // console.log(tableItem);

        customerService.getSimList(tableItem).then((data) => {
            if(isIterable(data)){
            let items = [];
            let names = [];
            let i = 1;
            for (let index of data) {
                let list = {};
                list["port"] = index.port;
                list["name"] = index.name.split("_")[0];
                items.push(list);

                let nameArray = names.map(function (el) {
                    return el.name;
                });
                if (!nameArray.includes(index.name.split("_")[0])) {
                    let kist1 = {};
                    kist1["name"] = index.name.split("_")[0];
                    kist1["id"] = i;
                    names.push(kist1);
                    i++;
                }
            }
            setdevice(names);
            setcustomer(items);
        }
        });
        customerService.getTransferHistory(tableItem).then((data) => {
            if(isIterable(data)){
            setHistorynew(getCustomersnew(data));
            setLoading1(false);
            }
        });

        initFilters1();
        // }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const searchnewlist = () => {
        if (user) {
            let date1 = new Date(startdate);
            let date2 = new Date(enddate);
            if (date2 < date1) {
                toast.current.show({ severity: "error", summary: "End Date Should Always Greater Or Equal To Start Date!", detail: "", life: 5000 });
                return false;
            }

            setLoading1(true);

            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.date = startdate;
            tableItem.enddate = enddate;
            customerService.getTransferHistory(tableItem).then((data) => {
                setHistorynew(getCustomersnew(data));
                setLoading1(false);
            });

            initFilters1();
        }
    };

    const isIterable = (obj) => {
        // checks for null and undefined
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === "function";
    };
    const getCustomersnew = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };

    const formatDate = (value) => {
        var dateA = new Date(value);
        var str = dateA.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
        return str.replace(/,/, " ");
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "AED" });
    };

    const clearFilter1 = () => {
        initFilters1();
    };

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1["global"].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    };

    const initFilters1 = () => {
        setFilters1({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            device_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            serial: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            to_port: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            from_port: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue1("");
    };

    const renderHeader1 = () => {
        return (
            <div>
                <div className="flex justify-content-between  card border-1 surface-border flex-column sm:flex-row mb-4">
                    <Calendar value={startdate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeStartdate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
                    <Calendar value={enddate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeEnddate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
                    <Button type="button" icon="pi pi-search" label="Search" className="p-button-outlined mb-2" onClick={searchnewlist} />
                    <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
                </div>
                <Divider layout="horizontal" align="center"></Divider>
                <div className="flex justify-content-between flex-column sm:flex-row ">
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined mb-2" onClick={clearFilter1} />
                    <Button type="button" icon="pi pi-refresh" label="Refresh" className="p-button-outlined mb-2" onClick={searchnewlist} />

                    <span className="p-input-icon-left mb-2">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" style={{ width: "100%" }} />
                    </span>
                </div>
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yyyy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.amount);
    };

    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="AED" locale="en-US" />;
    };

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span style={{ textAlign: "left", color: "purple" }} className="text-bold pr-6">
                    <b>
                        Total Balance Transfered
                    </b>
                </span>
                <span style={{ textAlign: "left", color: "purple" }} className="text-bold pr-6">
                    <b>
                        {calculateTotal(historynew)}
                    </b>
                </span>
            </React.Fragment>
        );
    };
    const calculateTotal = (historynew) => {
        let total = 0;

        for (let data of historynew) {
            total += data.amount;
        }

        return total.toLocaleString("en-US", { style: "currency", currency: "AED" });
    };

    const header1 = renderHeader1();
    if (!isLoggedIn) {
        // window.location.reload(false);
        return <Redirect to="/" />;
    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Balance Transfer</h5>
                    <div className="grid table-demo">
                        <Toast ref={toast} />
                        <Dialog header="Message" visible={displayBasic} style={{ width: "450px" }} className="p-fluid" modal footer={basicDialogFooter} onHide={() => setDisplayBasic(false)}>
                            <p>{response}</p>
                        </Dialog>
                        <div className="col-12">
                            <TabView>
                               
                                <TabPanel header="History">
                                    <div className="card">
                                        <h5>Recharge History</h5>

                                        <DataTable
                                            // ref={dt}
                                            value={historynew}
                                            paginator

                                            showGridlines
                                            rows={15}
                                            rowGroupMode="subheader"
                                            rowGroupHeaderTemplate={headerTemplate}
                                            rowsPerPageOptions={[10, 15, 50]}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                            sortMode="single"
                                            sortField={["id", "device_name","serial", "date", "amount", "from_port", "to_port"]}
                                            sortOrder={1}
                                            dataKey="id"
                                            filters={filters1}
                                            filterDisplay="menu"
                                            loading={loading1}
                                            className="p-datatable-gridlines datatable-responsive"
                                            globalFilterFields={["id", "device_name","serial", "date", "amount", "from_port", "to_port"]}
                                            header={header1}
                                            emptyMessage="No History found."
                                        >
                                            <Column sortable field="id" header="Id" filter filterPlaceholder="Search by Id" />
                                            <Column sortable field="device_name" header="Device" filter filterPlaceholder="Search by Device" />
                                            <Column sortable field="serial" header="Serial no" filterField="serial" filter filterPlaceholder="Search by Sl no."  />
                                            <Column sortable header="Date" filterField="date" dataType="date" body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
                                            <Column sortable header="Amount" filterField="amount" dataType="numeric" body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
                                            <Column sortable header="From Port" field="from_port" filter filterPlaceholder="Search by Port" />
                                            <Column sortable header="To Port" field="to_port" filter filterPlaceholder="Search by Port" />
                                            <Column body={actionBodyTemplate}></Column>
                                        </DataTable>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Transfer">
                                    <div className="card p-fluid">
                                        <h5>Transfer Balance From Master Sim</h5>
                                        <div className="formgrid grid">
                                            <div className="field col">
                                                <label htmlFor="dropdown">Device Name</label>
                                                <Dropdown id="dropdown" value={value8} options={device} itemTemplate={representativesItemTemplate} onChange={(e) => onDeviceChange(e)} optionLabel="name" placeholder="select device" className="p-column-filter" />
                                            </div>
                                            <div className="field col">
                                                <label htmlFor="fromdropdown">Master Sim Port</label>
                                                <Dropdown id="fromdropdown" value={fromport} options={dropcustomer} itemTemplate={portItemTemplate} onChange={(e) => onFromPortChange(e)} optionLabel="port" placeholder="select device" className="p-column-filter" />
                                            </div>
                                            <div className="field col">
                                                <label htmlFor="tpdropdown">To Port</label>
                                                <Dropdown id="tpdropdown" value={toport} options={dropcustomer} itemTemplate={portItemTemplate} onChange={(e) => onToPortChange(e)} optionLabel="port" placeholder="select device" className="p-column-filter" />
                                            </div>
                                            <div className="field col">
                                                <label htmlFor="amount">Amount</label>
                                                <InputText id="amount" value={amount} onChange={(e) => onAmountChange(e)} type="text" required className={classNames({ "p-invalid": submitted && !amount })} />
                                                {submitted && !amount && <small className="p-invalid">Amount is required.</small>}
                                            </div>
                                        </div>
                                        <div className="formgrid grid">
                                            <div className="field col-10"></div>
                                            <div className="field col">
                                                <Button label="Submit" onClick={transferbalance}></Button>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
