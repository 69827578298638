import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const AppSubmenu = (props) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const { user } = useSelector((state) => state.auth);
    const onMenuItemClick = (event, item, index) => {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        if (index === activeIndex) setActiveIndex(null);
        else setActiveIndex(index);

        if (props.onMenuItemClick) {
            props.onMenuItemClick({
                originalEvent: event,
                item: item,
            });
        }
    };

    const renderLinkContent = (item) => {
        let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item.badge && <Badge value={item.badge} />;

        return (
            <React.Fragment>
                <i className={item.icon}></i>
                <span>{item.label}</span>
                {submenuIcon}
                {badge}
                <Ripple />
            </React.Fragment>
        );
    };

    const renderLink = (item, i) => {
        // console.log(((item.label === "Dashboard" || item.label === "History" || item.label === "Recharge") && user.user.role === "user"))
        let content = renderLinkContent(item);
        
        // if ((item.label === "Dashboard" || item.label === "History" || item.label === "Payments" || item.label === "Recharge") && user.user.role === "user") {
            if (( item.label === "Recharges Report" || item.label === "Transactions Report" || item.label === "Vouchers Report" || item.label === "Sales Report")) {
            if (item.to) {
                return (
                    <NavLink className="p-ripple" activeClassName="router-link-active router-link-exact-active" to={item.to} onClick={(e) => onMenuItemClick(e, item, i)} exact target={item.target}>
                        {content}
                    </NavLink>
                );
            } else {
                return (
                    <a href={item.url} className="p-ripple" onClick={(e) => onMenuItemClick(e, item, i)} target={item.target}>
                        {content}
                    </a>
                );
            }
        }
        // else if (user.user.role === "admin") 
        // {
            // if (item.to) {
            //     return (
            //         <NavLink className="p-ripple" activeClassName="router-link-active router-link-exact-active" to={item.to} onClick={(e) => onMenuItemClick(e, item, i)} exact target={item.target}>
            //             {content}
            //         </NavLink>
            //     );
            // } else {
            //     return (
            //         <a href={item.url} className="p-ripple" onClick={(e) => onMenuItemClick(e, item, i)} target={item.target}>
            //             {content}
            //         </a>
            //     );
            // }
        // }
    };

    let items =
        props.items &&
        props.items.map((item, i) => {
            let active = activeIndex === i;
            let styleClass = classNames(item.badgeStyleClass, { "layout-menuitem-category": props.root, "active-menuitem": active && !item.to });

            if (props.root) {
                return (
                    <li className={styleClass} key={i}>
                        {props.root === true && (
                            <React.Fragment>
                                <div className="layout-menuitem-root-text">{item.label}</div>
                                <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
                            </React.Fragment>
                        )}
                    </li>
                );
            } else {
                return (
                    <li className={styleClass} key={i}>
                        {renderLink(item, i)}
                        <CSSTransition classNames="layout-submenu-wrapper" timeout={{ enter: 1000, exit: 450 }} in={active} unmountOnExit>
                            <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
                        </CSSTransition>
                    </li>
                );
            }
        });

    return items ? <ul className={props.className}>{items}</ul> : null;
};

export const AppMenu = (props) => {
    const { isLoggedIn } = useSelector((state) => state.auth);

    return (
        <div className="layout-menu-container">
            {isLoggedIn && <AppSubmenu items={props.model} className="layout-menu" onMenuItemClick={props.onMenuItemClick} root={true} />}
            {/* <a href="https://www.primefaces.org/primeblocks-react" className="block mt-3">
                <img alt="primeblocks" className="w-full"
                     src={props.layoutColorMode === 'light' ? 'assets/layout/images/banner-primeblocks.png' : 'assets/layout/images/banner-primeblocks-dark.png'}/>
            </a> */}
            {!isLoggedIn && <Redirect to="/login" />}
        </div>
    );
};
