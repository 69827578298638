import axios from "axios";
import { logout } from "../redux/actions";
// import { useDispatch } from "react-redux";
export default {
    setupInterceptors: (store) => {
        // const dispatch = useDispatch();
        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                //catches if the session ended!
                // console.log(error.response.status === 401);
                if (error.response.status === 401) {
                    store.dispatch(logout());
                }
                return Promise.reject(error);
            }
        );
    },
};
