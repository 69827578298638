import React from 'react';
import { useSelector } from "react-redux";

export const AppFooter = (props) => {
    const {  isLoggedIn } = useSelector((state) => state.auth);
    const styleObj = {
        opacity: 0
    }

    return (
        <div className="layout-footer" style={ !isLoggedIn ? styleObj : {}}>
            {/* <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="Logo" height="20" className="mr-2" />
            by */}
            <span className="font-medium ml-2">Paykart</span>
        </div>
    );
}
