import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { login } from "./redux/actions";
import Form from "react-validation/build/form";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
export const AppLogin = () => {
    const [username, setUsername] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const { isLoggedIn } = useSelector((state) => state.auth);
    // const { message } = useSelector((state) => state.msg);
    const toast = useRef(null);
    // const form = useRef();
    // setTimeout(function () {
    //     // alert('Reloading Page');
    //     window.location.reload(false);
    //   }, 1000);

    const [dropdownItem, setDropdownItem] = useState( { name: '971', code: '971' });
    const [dropdownItems, setDropdownItems] = useState( [
        { name: '971', code: '971' },
        { name: '974', code: '974' }
    ]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };
    const onChangemobile = (e) => {
        const mobile = e.target.value;
        setMobile(mobile);
    };
    const required = (value, name) => {
        if (!value) {
            toast.current.show({ severity: "error", summary: "This field is required!", detail: name, life: 5000 });
            return false;
        }
        return true;
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);
        if (!required(username, "username") || !required(password, "password")  || !required(mobile, "mobile")) {
            setLoading(false);
            return false;
        }

        // form.current.validateAll();
        // console.log(dropdownItem.code);
        dispatch(login(username,mobile, password,dropdownItem.code))
            .then(() => {
                //   props.history.push("/history");
                  window.location.reload("/history");
                setLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: error, detail: "", life: 5000 });

                setLoading(false);
            });
    };
    if (isLoggedIn) {
        return <Redirect to="/history" />;
    }
    return (
        <div className="justify-content-center surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <Toast ref={toast} />
            <div className="text-center mb-5">
                <img src="assets/layout/images/logo.png" alt="hyper" height="50" className="mb-3" />
                {/* <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div> */}
            </div>
            <Form >
            <div>
                <label htmlFor="user1" className="block text-900 font-medium mb-2">
                    Username
                </label>
                <InputText id="user1" type="text" name="username" value={username} validations={[required]} onChange={onChangeUsername} className="w-full mb-3" />

                <label htmlFor="mobile1" className="block text-900 font-medium mb-2">
                    Mobile
                </label>
                <InputText id="mobile1" type="text" name="mobile" value={mobile} validations={[required]} onChange={onChangemobile} className="w-full mb-3" />

                <label htmlFor="password1" className="block text-900 font-medium mb-2">
                    Password
                </label>
                <InputText id="password1" type="password" name="password" value={password} validations={[required]} onChange={onChangePassword} className="w-full mb-3" />
                <label htmlFor="country_code" className="block text-900 font-medium mb-2">
                    Country Code
                </label>
                {/* <InputText id="country_code" type="password" name="password" value={password} validations={[required]} onChange={onChangePassword} className="w-full mb-3" /> */}
                <Dropdown id="state" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} options={dropdownItems} optionLabel="name" className="w-full mb-3" placeholder="Select Country"></Dropdown>
                <Button type="submit" label="Sign In" icon={!loading ? "pi pi-user" : "pi pi-spin pi-spinner"} className="w-full" disabled={loading} onClick={handleLogin} />
            </div>
            </Form>
        </div>
    );
};
